import { memo, useEffect, useMemo, useRef } from 'react';
import CalendarTooltip from './CalendarTooltip';
import { useSelector } from 'react-redux';

const StartEndCalendarTooltip = ({ children, start, end, open, boundaryElement, observerElement }) => {
    const popperRef = useRef(null);
    const isMounted = useRef(false);

    const { isMobile } = useSelector(state => state.device);

    const defaultProps = useMemo(() => ({
        disableHoverListener: true,
        disableTouchListener: true,
        PopperProps: {
            disablePortal: true,
            modifiers: [
                {
                    name: "preventOverflow",
                    options: {
                        boundary: boundaryElement ?? 'body',
                        padding: 4,
                    }
                },
                {
                    name: 'flip',
                    enabled: !isMobile,
                    options: {
                        boundary: boundaryElement ?? 'body',
                        padding: {
                            top: 70,
                            // bottom: 4,
                            left: 4,
                            right: 4,
                        },
                    },
                },
                {
                    name: 'initUpdate',
                    enabled: true,
                    phase: 'main',
                    fn(bag) {
                        if (!isMounted.current) {
                            isMounted.current = true;
                            setTimeout(() => bag.instance.update(), 0);
                        }
                    },
                },
            ],
        },
    }), [boundaryElement]);

    const isStart = useMemo(() => (!!start && !end), [start, end]);

    const content = useMemo(() => {
        const nights = end?.clone()?.diff(start, 'days');
        return (
            <>
                <div>
                    <span>
                        {isStart
                            ? start.format('D MMM')
                            : `${start.format('D MMM')} - ${end.format('D MMM')}`
                        }
                    </span>
                </div>
                <div>
                    {isStart
                        ? 'Select check-out date'
                        : `(${nights} night${nights > 1 ? 's' : ''})`
                    }
                </div>
            </>
        );
    }, [isStart, start, end]);

    useEffect(() => {
        if (!observerElement) {
            return;
        }

        const cb = (mutationList, observer) => {
            popperRef.current?.update();
        };

        const observer = new MutationObserver(cb);
        observer.observe(observerElement, {
            attributes: true,
            attributeFilter: ['style'],
        });
        return () => observer.disconnect();
    }, [observerElement]);

    return (
        <CalendarTooltip
            title={content}
            {...defaultProps}
            open={open}
            popperRef={popperRef}
            sx={{
                backgroundColor: '#000',
            }}
            arrowSx={{
                color: '#000',
            }}
        >
            {children}
        </CalendarTooltip>
    );
};

export default memo(StartEndCalendarTooltip);
