import { memo } from "react";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import CurrencyCard from "../CurrencyCard";

const CurrencyCategory = ({
    sx,
    titleSlot,
    children,
    CardProps,
    TitleProps,
    CardWrapperProps,
    currencies = []
}) => {
    try {
        if (!currencies.length) {
            return null;
        }

        return (
            <Box
                sx={{
                    py: 2.5,
                    borderBottomStyle: 'solid',
                    borderBottomWidth: 1,
                    borderBottomColor: 'var(--color-light-grey)',
                    '@media (max-width: 600px)': {
                        marginLeft: 0,
                     },
                    ...sx
                }}
            >
                <Typography
                    {...TitleProps}
                    sx={{
                        fontWeight: '600 !important',
                        fontSize: '14px !important',
                        lineHeight: 1,
                        color: 'var(--color-black)',
                        fontFamily: 'var(--primary-font)',
                        '@media (max-width: 600px)': {
                           paddingLeft: '16px',
                        },
                        // ...TitleProps?.sx
                    }}
                >
                    {titleSlot ?? children}
                </Typography>
                <Box
                    {...CardWrapperProps}
                    sx={{
                        mt: 2.5,
                        display: 'grid',
                        gridTemplateColumns: 'repeat(4, minmax(0, 1fr))',
                        gap: 2.5,
                        ...CardWrapperProps?.sx
                    }}
                >
                    {currencies.map(currency => (
                        <CurrencyCard
                            {...CardProps}
                            key={currency.id}
                            info={currency}
                        />
                    ))}
                </Box>
            </Box>
        );
    } catch {
        return null;
    }
};

export default memo(CurrencyCategory);