import { memo } from 'react';
import clsx from 'clsx';
import { PromoInclusions } from '@/static/contants';
import PayLetterStickers from '../../icons/PayLater';
import { useSuckMyDickRDBitches } from '../hooks';

const PayLater = ({ className, ...restProps }) =>
    [
        useSuckMyDickRDBitches(restProps)
            .cancellationPolicies
    ]
        .flat()
        .filter(Boolean)
        .some(policy => Object.is(
            policy?.toLocaleLowerCase(),
            PromoInclusions.FREE_CANCELLATION.toLocaleLowerCase()
        )) && (
        <div className={clsx('property-card_pay-letter-sticker', className)}>
            <PayLetterStickers className='property-card_free-cancellation-sticker_icon' />
            <p>Pay Later</p>
        </div>
    );

export default memo(PayLater);
