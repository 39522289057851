import General from "@/components/map/helpers/General";
import { useSelector } from "react-redux";
import { useRouter } from "next/router";
import pluralize from "pluralize";
import { formatNumber } from "@/utils/globalFunc";

/**
 * Attention, attention!
 * This MUST be refactored
 */
export default function usePropertyPrice({ price, numberOfNights }) {
    const formatPrice = priceAmount => (
        `${price.currency_symbol}${formatNumber(priceAmount)}`
    );

    /** @converted */
    const isDiscountApplied = () => (
        price?.original_price > price?.amount
    );

    /** @converted */
    /** This is motherfucking bullshit, but we have to keep the consistency unfortunately */
    const getDiscountEntity = () => {
        if(!price){
            return;
        }
        const originalPrice = price.original_price;
        const amount = price.amount;
        const dropValue = originalPrice - amount;

        if (!isDiscountApplied()) {
            return {};
        }

        return {
            priceDrop: formatPrice(dropValue),
            oldPrice: formatPrice(originalPrice),
            cachePriced: formatNumber(dropValue),
            dropValue
        };
    };
    // const getDiscountEntity = () => General.getPropertyPriceDrop(price, numberOfNights) ?? {};
    const { currency } = useSelector(state => state.currencies);
    const router = useRouter();
    const adults = (Number.parseInt(router.query.adults) +  Number.parseInt(router.query.children))|| 1 
    const rooms = Number.parseInt(router.query.rooms) || 1;
    // const isDiscountApplied = (applyThreshold = 5) => {
    //     const discountEntity = getDiscountEntity();

    //     return (discountEntity.dropValue / price?.rate) >= applyThreshold;
    // };

    const isPriceValid = () => Boolean(General.getPropertyPrice(price));

    const getTotalPrice = () => formatPrice(price.amount);
        // General.getPropertyPrice({
        //     ...price,
        //     amount: (!!price.total_stay_price && !!price.rate)
        //         ? price.total_stay_price * price.rate
        //         : price.amount * numberOfNights
        //     // amount: price.amount * numberOfNights
        // });

    const getOriginalPrice = () => formatPrice(price.original_price);
        // General.getPropertyPrice({
        //     ...price,
        //     amount: price.original_price * currency.rate
        // });

    const getTaxesAndFees = () =>
        General.getPropertyPrice({
            ...price,
            amount: price.total_stay_tax * currency.rate
        });

    const renderTaxesAndFees = () => {
        const taxesAndFees = getTaxesAndFees();
        if (taxesAndFees || price.tax_and_fee) {
            return `+${taxesAndFees || `${price.currency_symbol}${Math.round(price.tax_and_fee * currency.rate)}`}
                ${price.tax_fee_label || 'taxes and fees'}`;
        }

        return `Excluding taxes and fees`;
    };

    const getNumberOfguestText = () =>
        `${pluralize('night', numberOfNights, true)}, ${pluralize('guest', adults, true)}${rooms > 1 ? `, ${rooms} rooms` : ''}`;

    return {
        isPriceValid,
        isDiscountApplied,
        getOriginalPrice,
        getTaxesAndFees,
        getTotalPrice,
        getDiscountEntity,
        renderTaxesAndFees,
        getNumberOfguestText
    };
};

