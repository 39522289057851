import Tooltip from '@mui/material/Tooltip';
import { useEffect, useRef } from 'react';

const CalendarTooltip = ({
    title,
    children,
    sx = {},
    arrowSx = {},
    PopperProps = {},
    popperRef,
    ...props
}) => {    
    return (
        <Tooltip
            title={title}
            arrow
            placement={'top'}
            enterTouchDelay={0}
            disableFocusListener
            TransitionProps={{ timeout: { enter: 300, exit: 10 } }}
            componentsProps={{
                tooltip: {
                    sx: {
                        fontFamily: 'var(--primary-font)',
                        fontSize: '12px',
                        lineHeight: '150%',
                        fontWeight: '400',
                        borderRadius: '9px',
                        backgroundColor: '#797882',
                        padding: '6px 16px',
                        '& div': {
                            whiteSpace: 'nowrap',
                        },
                        '& span': {
                            fontWeight: '600',
                            whiteSpace: 'nowrap',
                        },
                        ...sx
                    },
                },
                arrow: {
                    sx: {
                        color: '#797882',
                        ...arrowSx,
                    }
                }
            }}
            PopperProps={{
                ...PopperProps,
                modifiers: [
                    {
                        name: "offset",
                        options: {
                            offset: [0, -8],
                        },
                    },
                    // {
                    //     name: 'flip',
                    //     enabled: false
                    // },
                    ...(PopperProps.modifiers ?? [])
                ],
                popperRef,
            }}
            {...props}
        >
            {children}
        </Tooltip>
    )
};

export default CalendarTooltip;
